import {
  BlotterDensity,
  useAccordionFilterBuilder,
  useConstant,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type Column,
  type ColumnDef,
  type DateRangeFilter,
  type FundingEvent,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useFundingEventsFilter } from 'components/Filters/FilterBuilder/useFundingEventsFilter';
import { pick } from 'lodash';
import { useMemo } from 'react';

const COLUMNS: Column[] = [
  { type: 'account', field: 'MarketAccount', width: 200 },
  { type: 'currency', field: 'Asset' },
  { type: 'asset', field: 'PositionAsset' },
  { type: 'number', field: 'Amount', params: { currencyField: 'Asset' } },
  { type: 'number', field: 'Rate' },
  { type: 'date', field: 'TransactTime' },
  { type: 'text', field: 'SettleStatus' },
  { type: 'text', field: 'TransactionID', width: 250, hide: true },
] satisfies ColumnDef<FundingEvent>[];

export const useFundingEventsBlotter = () => {
  const request: WebsocketRequest = useConstant({
    name: 'FundingEvent',
    tag: 'BLOTTER_FUNDING_EVENTS',
  });

  const persistedBlotterTable = usePersistedBlotterTable('FundingEvents', {
    columns: COLUMNS,
  });

  const filteredFundingEvents = useFundingEventsFilter({ persistedBlotterTable });
  const { blotterTableFilterProps, filterBuilderProps, clientSideFilter: clientLocalFilter } = filteredFundingEvents;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const blotterTable = useWsBlotterTable<WebsocketRequest, FundingEvent>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    filter: onlyServerFilterKeys(filteredFundingEvents.filter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    rowID: 'TransactionID',
    density: BlotterDensity.Comfortable,
    clientLocalFilter,
    gridOptions: {
      animateRows: true,
      rowHeight: 40,
    },
  });

  return useMemo(
    () => ({ blotterTable, blotterTableFilterProps, filterBuilderAccordion }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};

function onlyServerFilterKeys(filter: DateRangeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate', 'MarketAccounts']);
}

import Big, { type BigSource } from 'big.js';
import type { Security } from '../../../types';
import { HStack } from '../../Core';
import { InlineFormattedNumber, NumberVariants } from '../../FormattedNumber';
import { Text } from '../../Text';

import type { ReactNode } from 'react';
import { SummaryLine } from '../SummaryLine';

interface BalanceDetailsSummaryLineProps {
  accountID?: string;
  security?: Security;
  estimate?: boolean;
  baseBalance?: BigSource;
  quoteBalance?: BigSource;
  warnOnPositive?: boolean;
  title: ReactNode;
}

export const BalanceDetailsSummaryLine = ({
  accountID,
  security,
  estimate,
  baseBalance,
  quoteBalance,
  warnOnPositive,
  title,
  ...rest
}: BalanceDetailsSummaryLineProps) => {
  const { BaseCurrency, QuoteCurrency, DefaultPriceIncrement, DefaultSizeIncrement } = security ?? {};

  const warnBase = warnOnPositive && Big(baseBalance ?? '0').gt(0);
  const warnQuote = warnOnPositive && Big(quoteBalance ?? '0').gt(0);

  return (
    <SummaryLine
      {...rest}
      title={<Text color={warnBase || warnQuote ? 'colorTextWarning' : undefined}>{title}</Text>}
      value={
        accountID && security ? (
          <HStack flex="1" justifyContent="flex-end" gap="spacingSmall" whiteSpace="nowrap">
            {estimate ? '~' : ''}
            <InlineFormattedNumber
              number={baseBalance ?? '0'}
              increment={DefaultSizeIncrement}
              currency={BaseCurrency}
              align="right"
              variant={
                warnBase ? NumberVariants.Warning : Big(baseBalance ?? '0').lt(0) ? NumberVariants.Negative : undefined
              }
            />
            /
            <InlineFormattedNumber
              number={quoteBalance ?? '0'}
              increment={DefaultPriceIncrement}
              currency={QuoteCurrency}
              align="right"
              variant={
                warnQuote
                  ? NumberVariants.Warning
                  : Big(quoteBalance ?? '0').lt(0)
                  ? NumberVariants.Negative
                  : undefined
              }
            />
          </HStack>
        ) : (
          '-'
        )
      }
    />
  );
};

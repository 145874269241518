import {
  BALANCE_TRANSACTION,
  BlotterDensity,
  cancelPendingApprovalWithdrawalRequest,
  CustomerBalanceTransactionStatusEnum,
  useAccordionFilterBuilder,
  useConstant,
  useGetDefaultContextMenuItems,
  usePersistedBlotterTable,
  useSocketClient,
  useWsBlotterTable,
  type Column,
  type CustomerBalanceTransaction,
  type DateRangeFilter,
  type WebsocketRequest,
} from '@talos/kyoko';
import type { ValueGetterParams } from 'ag-grid-community';
import { compact, pick } from 'lodash';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useTransfersFilter } from '../../../../components/Filters/FilterBuilder';

const messages = defineMessages({
  notSpecified: {
    defaultMessage: 'Not Specified',
    id: 'Blotters.TransfersBlotter.notSpecified',
  },
});

// TODO these columns are wrong, the fields that is. Need to type correctly so we get alerted
const COLUMNS: Column[] = [
  { type: 'date', field: 'Timestamp', width: 225, sortable: true, sort: '-' },
  { type: 'account', field: 'MarketAccount' },
  { type: 'currency', field: 'Currency', width: 200 },
  { type: 'size', field: 'Quantity', params: { currencyField: 'Currency' }, width: 200 },
  { type: 'transactionType', field: 'TransactionType', width: 150 },
  { type: 'transferStatus', field: 'Status', width: 225 },
  { type: 'text', field: 'ExternalComments', title: { intlKey: 'comments' } },
  {
    type: 'custom',
    field: 'TxHash',
    width: 500,
    params: {
      valueGetter: ({ data, context }: ValueGetterParams) =>
        data.Status === CustomerBalanceTransactionStatusEnum.Completed
          ? data.TxHash || context.current.intl.formatMessage(messages.notSpecified)
          : '',
    },
  },
];

interface UseTransfersBlotterProps {
  maxRows: number;
  tag: string;
}

const DEFAULT_SORT = '-Timestamp';

export const useTransfersBlotter = ({ maxRows, tag }: UseTransfersBlotterProps) => {
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const client = useSocketClient();

  const request: WebsocketRequest = useConstant({
    name: BALANCE_TRANSACTION,
    tag: `${tag}/useTransfersBlotter`,
    sort_by: DEFAULT_SORT,
  });

  const persistedBlotterTable = usePersistedBlotterTable('transfers', {
    columns: COLUMNS,
    sort: DEFAULT_SORT,
  });

  const filteredTrades = useTransfersFilter({ persistedBlotterTable });
  const { blotterTableFilterProps, filterBuilderProps } = filteredTrades;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const blotterTable = useWsBlotterTable<WebsocketRequest, CustomerBalanceTransaction>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    filter: onlyServerFilterKeys(filteredTrades.filter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    rowID: 'TransactionID',

    density: BlotterDensity.Comfortable,
    gridOptions: {
      animateRows: true,
      rowHeight: 40,
      getContextMenuItems: params =>
        compact([...getDefaultContextMenuItems(params), cancelPendingApprovalWithdrawalRequest(params, client)]),
    },
  });

  return useMemo(
    () => ({ blotterTable, blotterTableFilterProps, filterBuilderAccordion }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};

function onlyServerFilterKeys(filter: DateRangeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate']);
}
